import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { useLanguageContext } from '@/base/context/LanguageContext'

import { Link } from '@/components/elements/Link'
import { Image } from '@/components/elements/Image'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

export const PageNotFound = ({}) => {
  const { t } = useLanguageContext()

  const data = useStaticQuery(
    graphql`
      {
        files: allFile(filter: { name: { eq: "illustration-page-not-found" } }) {
          nodes {
            childImageSharp {
              gatsbyImageData(width: 800, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }
    `
  )

  return (
    <Section>
      <div className={styles.section}>
        <div className={styles.message}>
          <h1>{t`404.title`}</h1>
          <p>{t`404.subtitle`},</p>
          <p>{t`404.description`}</p>

          <div className={styles.links}>
            <p>{t`404.links.title`}</p>
            <div>
              <Link to={t`404.links.home.to`} label={t`404.links.home.label`} variant="link-arrow" />
              <Link to={t`404.links.quote.to`} label={t`404.links.quote.label`} variant="link-arrow" />
              <Link to={t`404.links.blog.to`} label={t`404.links.blog.label`} variant="link-arrow" />
            </div>
          </div>
        </div>

        <Image image={data?.files?.nodes?.[0]?.childImageSharp} objectFit="contain" className={styles.image} />
      </div>
    </Section>
  )
}
