import React from 'react'

import { LanguageContextProvider } from '@/base/context/LanguageContext'

import { PageNotFound } from '@/components/sections/NotFound/Page'
import { Header } from '@/components/sections/Header'

const NotFoundPage = ({ location }) => (
  <LanguageContextProvider
    alternateLanguagePagePaths={{ en: '/404/', es: '/404/' }}
    language_code={location.pathname.startsWith('/es') ? 'es' : 'en'}
  >
    <Header />
    <PageNotFound />
  </LanguageContextProvider>
)

export default NotFoundPage
